import React, { useEffect } from 'react';
import { navigate, graphql, PageProps } from 'gatsby';
import { Para, Button, Flex } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useAppDispatch } from '@hooks/redux-hooks';
import Layout from '@containers/Layout';
import useTranslation from '@hooks/useTranslation';
import { setCurrentRoute } from '@slices/routeSlice';
import BodyWrapper from '@components/BodyWrapper';
import { gameHasFinished, setHeaderType } from '@slices/gameStateSlice';
import gameConfig from '@content/gameconfig';
import getSymbol from '@utils/getSymbol';
import { logItem } from '@slices/loggingSlice';
import useNavigateLog from '@hooks/useNavigateLog';
import BackgroundImage from '@components/BackgroundImage';
import SourcesTrayButton from '@containers/SourcesTrayButton';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import { WINDOW_HASH } from '@sharedConstants';
import SubmitFeedbackModalButton from '@containers/SubmitFeedbackModalButton';

const PlayAgainButton = styled(Button)(
  css({
    alignSelf: 'center',
  }),
);

const CenterContentWrapper = styled(Flex)({
  isolation: 'isolate',
  mt: 2,
  flex: 0.5,
  flexDirection: 'column',
});

const BottomContentWrapper = styled(Flex)({
  isolation: 'isolate',
  flex: 1,
  flexDirection: 'column',
  alignSelf: 'center',
});

const EndPage = ({ data }: PageProps<Queries.EndPageQuery>) => {
  const endPageBgSymbol = getSymbol(data.endPageBgSymbol);
  useNavigateLog({ questionName: 'end' });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setCurrentRoute({ compareAgainst: 'end', findBy: 'name' }));
    dispatch(setHeaderType({ headerType: 'shareable' }));
    dispatch(gameHasFinished());
  }, [dispatch]);

  return (
    <Layout>
      <BodyWrapper overflow="hidden" backgroundColor="transparent">
        {endPageBgSymbol && (
          <BackgroundImage
            imageData={endPageBgSymbol.data}
            imageType={endPageBgSymbol.type}
          />
        )}
        <Flex flex={1} />
        <CenterContentWrapper>
          <Flex flex={0.5} />
          <Para>
            <CustomMdxRenderer>{t('End Text', true)}</CustomMdxRenderer>
          </Para>
          {gameConfig.Enable_Sources_Modal && (
            <Flex alignSelf="center">
              <SourcesTrayButton />
            </Flex>
          )}
          {gameConfig.Enable_Feedback && (
            <Flex alignSelf="center">
              <SubmitFeedbackModalButton questionName="end" />
            </Flex>
          )}
        </CenterContentWrapper>
        <BottomContentWrapper>
          <PlayAgainButton
            data-cy="play-again"
            onPress={() => {
              dispatch(
                logItem({
                  collection_name: 'events',
                  event_type: 'click',
                  location: window?.location?.pathname,
                  target: 'play-again-button',
                }),
              );
              navigate(`/${WINDOW_HASH}`, {
                state: { cameFromEnd: true },
              });
            }}
          >
            <Para>{t('Play Again')}</Para>
          </PlayAgainButton>
        </BottomContentWrapper>
      </BodyWrapper>
    </Layout>
  );
};

export const query = graphql`
  query EndPage {
    endPageBgSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Claim Background Image" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;

export default EndPage;
