import React, { useCallback } from 'react';
import {
  Tray,
  IconButton,
  Para,
  Button,
  Flex,
  Label,
  Divider,
  Heading,
} from 'workspace-core-ui';
import { useOverlayTriggerState } from 'react-stately';
import useTranslation from '@hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '@hooks/redux-hooks';
import { logItem } from '@slices/loggingSlice';
import sources from '@content/resources';
import gameConfig from '@content/gameconfig';
import CustomMdxRenderer from './CustomMdxRenderer';

interface SourcesTrayButton {
  buttonProps?: any;
}

const SourcesTrayButton = (props: SourcesTrayButton) => {
  const { allRoutes } = useAppSelector(state => state.route);
  const { t, g } = useTranslation();
  const dispatch = useAppDispatch();
  const { buttonProps } = props;
  const overlayState = useOverlayTriggerState({});
  // TODO: I am trying to recycle some content tags here
  const viewSourcesButtonText = t('View Sources Button');

  const TrayHeader = useCallback(
    () => (
      <Flex p={[4, 6, 6]}>
        <Flex flex={1}>
          <IconButton
            data-cy="closeSourcesModalIcon"
            onPress={() => overlayState.close()}
            showOutline={false}
            iconName="close"
          >
            {/* TODO: localize */}
            Close
          </IconButton>
        </Flex>
      </Flex>
    ),
    [overlayState],
  );

  return (
    <>
      <Button
        data-cy="sourcesButton"
        aria-label="sources"
        mt={4}
        onClick={() => {
          dispatch(
            logItem({
              collection_name: 'events',
              event_type: 'click',
              target: `view sources end open button`,
            }),
          );
          overlayState.open();
        }}
        buttonSize="medium"
        variant="secondary"
        {...buttonProps}
      >
        <Label>{viewSourcesButtonText}</Label>
      </Button>
      <Tray
        title={viewSourcesButtonText}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        HeaderComponent={TrayHeader}
        isDismissable
      >
        <Heading mb={6} variant="h2">
          {t('View Sources Button')}
        </Heading>

        {gameConfig.Use_Custom_Sources_Content ? (
          <Para variant="p3">
            <CustomMdxRenderer>
              {t('Custom Sources Modal Content', true)}
            </CustomMdxRenderer>
          </Para>
        ) : (
          <>
            {/* we only want to show the resources that your actually 'saw' in game */}
            {sources
              .filter(e => allRoutes.find(r => r.name === e.Name))
              .map(resource => (
                <>
                  <Para mb={2} variant="p3">
                    <CustomMdxRenderer>
                      {g(resource.Correct_Text, true)}
                    </CustomMdxRenderer>
                  </Para>
                  <Flex my={3}>
                    {/* TODO: localize */}
                    <Label>{t('Result Why True Body Header')}</Label>
                  </Flex>
                  <Para
                    variant="p4"
                    dangerouslySetInnerHTML={{
                      __html: g(resource.Sources, false),
                    }}
                  />
                  <Divider my={4} />
                </>
              ))}
          </>
        )}
        <Flex mt={8}>
          <Button
            data-cy="closesourcesModalButton"
            onPress={overlayState.close}
          >
            <Label variant="l1">{t('About Modal Back Button')}</Label>
          </Button>
        </Flex>
      </Tray>
    </>
  );
};

export default SourcesTrayButton;
