import React, { useState } from 'react';
import { Box, Modal, Flex, Button, Para, Label } from 'workspace-core-ui';
import { useOverlayTriggerState } from 'react-stately';
import { useAppDispatch } from '@hooks/redux-hooks';
import useTranslation from '@hooks/useTranslation';
import Input from 'workspace-core-ui/Input';
import { logItem } from '@slices/loggingSlice';
import { addNewNotification } from '@slices/gameStateSlice';
import { motion } from 'framer-motion';

interface SubmitFeedbackModalButtonProps {
  questionName: string;
  buttonProps?: any;
}

const SubmitFeedbackModalButton = (props: SubmitFeedbackModalButtonProps) => {
  const [feedbackText, setFeedbackText] = useState('');
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { buttonProps, questionName } = props;
  const overlayState = useOverlayTriggerState({});

  return (
    <>
      <Button
        my={4}
        onPress={() => {
          overlayState.open();
        }}
        buttonSize="medium"
        variant="secondary"
        {...buttonProps}
      >
        <Label variant="l3">{t('Feedback Button')}</Label>
      </Button>
      <Modal
        title={t('Feedback Modal Header')}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        isDismissable
        showClose
        closeLabel={t('Close')}
      >
        <Flex
          maxWidth="600px"
          mt={[3, 3, 5]}
          as="form"
          flexDirection="column"
          justifyContent="center"
          onSubmit={e => {
            e.preventDefault();
            dispatch(
              logItem({
                event_type: 'feedback',
                question_name: questionName,
                collection_name: 'events',
                target: feedbackText,
              }),
            );
            dispatch(addNewNotification(t(`Feedback Sent Notification`)));
            overlayState.close();
            setFeedbackText('');
          }}
        >
          <Flex as={motion.div} layout>
            <Para mb={6} variant="p3">
              {t('Feedback Modal Body')}
            </Para>
          </Flex>

          <Flex>
            <Box width="100%">
              <Input
                autoComplete="off"
                flex={1}
                onInput={e => {
                  setFeedbackText(e.target.value);
                }}
                as="textarea"
                mt={4}
                label={t('Feedback Input Label')}
                placeholder={t('Feedback Input Placeholder')}
              />
            </Box>
          </Flex>
          <Flex justifyContent="center">
            <Button
              type="submit"
              mt={4}
              buttonSize="medium"
              isDisabled={feedbackText.length === 0}
            >
              {t('Submit Button')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default SubmitFeedbackModalButton;
